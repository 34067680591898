@import './theme';
@import '~bootstrap/scss/bootstrap';

::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}